.slide-content {
  height: 100%;
  position: relative;
  transition: left 0.7s ease, right 0.7s ease, top 0.7s ease, bottom 0.7s ease;
  padding: 10px;
  &__inner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    height: 100%;
    box-shadow: 18.4px 19.7px 44.8px 9.2px rgba(0, 0, 0, 0.81);
  }

  &.line &__inner {
    box-shadow: 38.9px 41.7px 44.8px 9.2px rgba(0, 0, 0, 0.81);
  }
}