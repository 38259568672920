.spd-carousel-direction {
  position: absolute;
  top: 50%;
  margin-top: -28px;
  width: 110px;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.8);
  &__icon {
    font-size: 40px;
  }
  &__icon,
  &__title {
    color: #007EE1;
    vertical-align: middle;
  }

  &__title {
    text-transform: capitalize;
    position: absolute;
    top: 50%;
    line-height: 1;
    margin-top: -6.5px;
  }

  &:focus {
    outline: none;
  }
  &.prev {
    transition: left 0.5s ease;
    left: -75px;
    text-align: right;
  }

  &.prev:hover {
    left: 5px;
  }
  &.next {
    right: -75px;
    transition: right 0.5s ease;
    text-align: left;
  }

  &.next:hover {
    right: 5px;
  }

  &.next &__title {
    right: 5px;
  }

  &.next &__icon {
    margin-left: 5px;
  }

  &.prev &__title {
    left: 5px;
  }

  &.prev &__icon {
    margin-right: 5px;
  }
}