.slide-container {
  height: inherit;
  padding: 8% 12% 6%;
  //max-width: 1520px;
  //margin: 0 auto;
  & >.slide-col {
    width: 33.3%;
    //background-color: rgba(0, 0, 0, 0.5);
    height: inherit;
    float: left;
  }

  & > .slide-col-1 > .slide-row {
    height: 100%;
  }

  & > .slide-col-3 > .slide-row {
    height: 33.3%;
  }
}
