.spd-carousel-slide {
  width: 100%;
  position: absolute;
  float: left;
  transition: left 0.7s ease, right 0.7s ease, top 0.7s ease, bottom 0.7s ease;
  height: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  //height: 1080px;
}