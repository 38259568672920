@import "content";
@import "content-wr";
@import "slide";
@import "slide-container";
@import "slide-content";
@import "slide-row";
@import "direction";

* {
  box-sizing: border-box;
}

.spd-carousel {
  position: relative;
  overflow: hidden;
}